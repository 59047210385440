
import { mapActions, mapMutations } from 'vuex';
import NewArticleCard from './NewArticleCard.vue';
import NewArticleCardAd from './NewArticleCardAd.vue';
import MixArticleLoading from './MixArticleLoading.vue';
export default {
    name: "mix-article-container",
    components: { NewArticleCard, NewArticleCardAd, MixArticleLoading },
    props: {
        is_mobile: Boolean,
    },
    data() {
        return {
            next_page: 2,
            viewWidth: 0,
        }
    },
    computed: {
        is_loading() {
            return this.$store.state.mix_article.is_loading;
        },
        mix_article_data() {
            return this.$store.state.mix_article.mix_article_data;
        },
        article_cursor() {
            return this.$store.state.mix_article.article_cursor;
        },
        article_loading() {
            return this.$store.state.mix_article.is_loading;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        columns() {
            if (this.viewWidth >= 1320) return 4;
            if (this.viewWidth <= 1024) return 2;
            return 3;
        },
    },
    methods: {
        ...mapActions('mix_article', [
            'getMixArticleData',
            'getMoreMixArticleData'
        ]),
        ...mapMutations('mix_article', [
            'setLoadingStatus',
        ]),
        async getMoreMixArticleDataAndAddPage() {
            const params = {
                member_token: this.member_token,
                cursor: this.article_cursor,
                page: this.next_page
            }
            this.setLoadingStatus(true);
            await this.getMoreMixArticleData(params);
            this.next_page = this.next_page + 1;
            this.setLoadingStatus(false);
        },
        async handleScroll() {
            // 當滾動到底部時加載更多數據
            if (
                window.innerHeight + window.scrollY >= document.body.offsetHeight * 0.7 &&
                !this.article_loading
            ) {
                await this.getMoreMixArticleDataAndAddPage();
            }
        },
        checkViewWidth() {
            this.viewWidth = window.innerWidth;
        }
    },
    async mounted() {
        this.checkViewWidth();
        this.setLoadingStatus(true);
        await this.getMixArticleData({
            member_token: this.member_token,
            is_mobile: this.is_mobile,
        });
        this.setLoadingStatus(false);
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener('resize', this.checkViewWidth);
    },
    beforeDestroy() {
        // 移除滾動事件的監聽
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener('resize', this.checkViewWidth);
    }
}
